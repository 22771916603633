<template>
  <el-card shadow="never" class="aui-card--fill">
    <el-container>
      <el-aside class="left-box">
        <el-tabs v-model="activeTabName" @tab-click="tabHandleClick" type="border-card">
          <el-tab-pane name="catalog" class="tree-container">
            <span slot="label"><i class="el-icon-notebook-1"></i> 图纸目录</span>
            <el-tree
              class="left-tree"
              v-loading="treeLoading"
              :data="treeData"
              node-key="id"
              :props="defaultProps"
              ref="tree"
              @node-click="treeNodeClick"
              :highlight-current="true"
              :filter-node-method="filterNode">
            </el-tree>
          </el-tab-pane>
          <el-tab-pane name="wbs" class="tree-container">
            <span slot="label"><i class="el-icon-set-up"></i> 分部分项</span>
            <el-tree
              class="left-tree"
              v-loading="wbsTreeLoading"
              :data="wbsTreeData"
              node-key="id"
              :props="defaultProps"
              ref="wbsTree"
              lazy
              :load="loadWbsNode"
              :expand-on-click-node="false"
              @node-click="wbsTreeNodeClick"
              :highlight-current="true">
            </el-tree>
          </el-tab-pane>
        </el-tabs>
      </el-aside>
      <el-main>
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
          <el-form-item>
            <project-select v-model="dataForm.prjId" placeholder="项目选择" type='authority' @change="projectChangeHandle" :defaultSelect="true"></project-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="dataForm.name" placeholder="图纸名称" clearable></el-input>
          </el-form-item>
          <template v-if="dataForm.prjId">
            <el-form-item>
              <el-button @click="getDataList()" round icon="el-icon-search">{{ $t('query') }}</el-button>
            </el-form-item>
            <el-form-item>
              <el-button v-if="$hasPermission('dfms:paper:save')" type="primary" @click="paperUploadHandle" round icon="el-icon-upload2">上传图纸</el-button>
            </el-form-item>
          </template>
        </el-form>
        <el-table highlight-current-row v-loading="dataListLoading" :data="dataList" border @selection-change="dataListSelectionChangeHandle" @sort-change="dataListSortChangeHandle" style="width: 100%;">
          <el-table-column prop="name" label="图纸名称" header-align="center" align="left" show-overflow-tooltip sortable="custom" min-width="200">
            <template slot-scope="scope">
              <el-button type="text" @click="viewHandle(scope.row.id)">{{ scope.row.name }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="version" label="图纸版本" header-align="center" align="center" sortable="custom" width="120">
            <template slot-scope="scope">
              <el-button type="text" @click="openPaperHandle(scope.row.id)">{{ scope.row.version }}</el-button>
            </template>
          </el-table-column>
          <el-table-column v-if="activeTabName=='catalog'" prop="catalogName" label="所属目录" show-overflow-tooltip min-width="160"></el-table-column>
          <el-table-column v-if="activeTabName=='wbs'" prop="wbsName" label="工程分解" header-align="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="creatorName" label="创建人" header-align="center" align="center" width="100"></el-table-column>
          <el-table-column prop="createDate" label="创建时间" header-align="center" align="center" width="140" sortable="custom"></el-table-column>
          <el-table-column label="状态" prop="createDate" fixed="right" header-align="center" align="center" width="100" sortable="custom">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.active === 1" size="small" type="success">已发布</el-tag>
              <template v-else>
                <el-tag size="small" type="info">未发布</el-tag>
              </template>
            </template>
          </el-table-column>
          <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="140">
            <template slot-scope="scope">
              <el-button v-if="$hasPermission('dfms:paper:update') && scope.row.active === 1" size="small" type="text" @click="paperUpgradeHandle(scope.row)">升版</el-button>
              <template v-if="scope.row.active !== 1">
                <el-button v-if="$hasPermission('dfms:paper:update')" type="text" size="small" @click="releaseHandle(scope.row)">发布</el-button>
              </template>
              <el-button v-if="$hasPermission('dfms:paper:delete')" type="text" size="small" style="color: red" @click="deleteHandle(scope.row.id)">{{ $t('delete') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
        </el-pagination>
      </el-main>
    </el-container>
    <!-- 弹窗, 查看 -->
    <view-page v-if="viewVisible" ref="view" @close="closePaperViewDialogHandle"></view-page>
    <!-- 弹窗 图纸上传-->
    <paper-upload v-if="paperUploadVisible" ref="paperUpload" @refreshDataList="getDataList" @close="closePaperUploadDialogHandle"></paper-upload>
    <!--弹窗 图纸升版-->
    <paper-upgrade v-if="paperUpgradeVisible" ref="paperUpgrade" @refreshDataList="getDataList" @close="closePaperUpgradeDialogHandle"></paper-upgrade>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import paperUpload from './paper-upload'
import ViewPage from './paper-edit'
import paperUpgrade from './paper-upgrade'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/paper/page',
        getDataListIsPage: true,
        deleteURL: '/mps/paper',
      },
      dataForm: {
        name: '',
        prjId: '',
        catalogId: '',
        wbsId: ''
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        isLeaf: 'isLeaf'
      },
      treeFilterText: '',
      treeLoading: false,
      treeNodeIsLeaf: false,
      paperUploadVisible: false,
      paperUpgradeVisible: false,
      // 对应附件类型分类枚举
      uploadTypeIds: {
        PAPER_SRC: '图纸源文件',
        PAPER_PDF: '图纸PDF文件',
        PAPER_PNG: '图纸图片',
        PAPER_THUMBNAIL: '图纸缩略图',
        PAPER_ATTACHMENT: '图纸附件',
      },

      activeTabName: 'catalog', // 图纸目录：catalog 、工程分解: wbs
      wbsTreeLoading: false,
      wbsTreeData: [],
      wbsTreeNodeIsLeaf: false
    }
  },
  watch: {
    treeFilterText (val) {
      this.$refs.tree.filter(val)
    },
  },
  components: {
    paperUpload,
    paperUpgrade,
    ViewPage
  },
  methods: {
    // 项目选择下拉框改变事件
    projectChangeHandle (data) {
      // 切换项目时，清空当前选中的图纸目录ID
      this.treeNodeIsLeaf = false
      this.dataForm.catalogId = ''
      this.$refs.tree.setCurrentKey(null)

      this.dataForm.prjId = data ? data.id : ''
      this.getDataList()
      this.getTreeData()
      this.getWbsTreeData()
    },

    //异步加载wbs子节点数据
    loadWbsNode (node,resolve) {
      //此方法是进入页面后自动运行的,两个参数也是默认加载的
      // 只处理子节点懒加载
      if (node.level >= 1) {
        if (node && node.data) {
          let wbsData = node.data;
          if (wbsData.id == '-1') {
            return resolve([])
          } else {
            this.$http.get(
              '/mps/paper/findWbsChildren',
              {
                params: {
                  pid: wbsData.id,
                  contractId: wbsData.contractId,
                  subcontractorId: wbsData.subcontractorId
                }
              }
            ).then(({data: res}) => {
              if (res.code !== 0) {
                // 返回出错
                return this.$message.error(res.msg)
              }
              resolve(res.data);
            }).catch(() => {
              // 查询出错
              return this.$message.error('出错了')
            })
          }
        } else {
          return resolve([])
        }
      }
    },

    getTreeData () {
      this.treeLoading = true
      this.$http.get('/mps/paperCatalog/treeList',
        {
          params: {
            prjId: this.dataForm.prjId,
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.treeData = res.data
        this.treeData.push({
          id: '-1',
          name: '其他未分类图纸',
          isLeaf: false,
          children: []
        })
        this.treeNodeIsLeaf = false
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.treeLoading = false
      })
    },

    filterNode (value,data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },

    treeNodeClick (data,node,ele) {
      // 清除 工程分解的选择
      this.wbsTreeNodeIsLeaf = false
      this.dataForm.wbsId = ''
      this.$refs.wbsTree.setCurrentKey(null)

      // 根据点击的节点是否为叶节点，修改本地变量，用于判断是否能新增
      // 点击高亮节点则取消高亮，并查全部清单的数据
      if (this.dataForm.catalogId == data.id) {
        this.treeNodeIsLeaf = false
        this.dataForm.catalogId = ''
        this.$refs.tree.setCurrentKey(null)
      } else {
        this.treeNodeIsLeaf = node.isLeaf
        this.dataForm.catalogId = data.id
      }
      this.getDataList()
    },
    // 图纸上传按钮点击事件
    paperUploadHandle () {
      if (!this.dataForm.prjId) {
        this.$message({
          message: '请先选择项目！',
          type: 'error',
          duration: 1000
        })
      } else {
        if (this.dataForm.catalogId && this.dataForm.catalogId != '-1') {
          this.$http.get(`/mps/paperCatalog/${this.dataForm.catalogId}`).then(({data: res}) => {
            if (res.code == 0) {
              if (res.data.name) {
                this.$confirm(`是否要将图纸上传到[${res.data.name}]？`,this.$t('prompt.title'),{
                  confirmButtonText: this.$t('confirm'),
                  cancelButtonText: this.$t('cancel'),
                  type: 'warning'
                }).then(() => {
                  this.paperUploadVisible = true
                  this.$nextTick(() => {
                    this.$refs.paperUpload.prjId = this.dataForm.prjId
                    this.$refs.paperUpload.catalogId = this.dataForm.catalogId
                    this.$refs.paperUpload.catalogName = res.data.name
                    this.$refs.paperUpload.init()
                  })
                })
              }
            }
          }).catch(() => {
            // do nothing
          })
        } else {
          this.paperUploadVisible = true
          this.$nextTick(() => {
            this.$refs.paperUpload.prjId = this.dataForm.prjId
            this.$refs.paperUpload.catalogId = this.dataForm.catalogId
            this.$refs.paperUpload.catalogName = ''
            this.$refs.paperUpload.init()
          })
        }
      }
    },
    // 图纸上传窗口关闭事件
    closePaperUploadDialogHandle () {
      this.paperUploadVisible = false
      this.getDataList()
    },
    // 列表页面的图纸发布（用户在图纸上传的最后环节未点击发布按钮，图纸状态就是未发布）
    releaseHandle (row) {
      const loading = this.$loading({
        lock: true,
        text: '正在发布图纸，请稍等...',
        customClass: 'create-isLoading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const data = [{id: row['id'],prjId: row['prjId']}]
      this.$http.post(`/mps/paper/release?_t=${new Date()}`,data)
        .then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          } else {
            this.$message({
              message: '图纸发布成功。',
              type: 'success',
              duration: 2000,
              onClose: () => {
                this.getDataList()
              }
            })
          }
        }).catch(() => {
        this.$message({
          message: '发布图纸过程中出现错误，请稍后重试！',
          type: 'error',
          duration: 5000
        })
      }).finally(() => {
        loading.close()
      })
    },

    // 图纸升版 按钮点击事件
    paperUpgradeHandle (row) {
      if (!row) {
        this.$message({
          message: '请先选择需要升版的图纸！',
          type: 'error',
          duration: 1000
        })
      } else {
        this.paperUpgradeVisible = true
        this.$nextTick(() => {
          this.$refs.paperUpgrade.prjId = row['prjId']
          this.$refs.paperUpgrade.paperId = row['id']
          this.$refs.paperUpgrade.paperName = row['name']
          this.$refs.paperUpgrade.paperPreviousVersion = row['version']
          this.$refs.paperUpgrade.init()
        })
      }
    },

    // 图纸升版 窗口关闭事件
    closePaperUpgradeDialogHandle () {
      this.paperUpgradeVisible = false
      this.getDataList()
    },

    // 图纸查看页面关闭后，刷新图纸列表。（图纸查看页面可能修改了图纸目录）
    closePaperViewDialogHandle () {
      this.viewVisible = false
      this.getDataList()
    },
    // 直接打开图纸对应的PDF文件
    openPaperHandle (refId) {
      // 根据图纸上传时的原始Url，重新获取授权的Url（避免文件路径在不登录时就能随意访问的安全问题）
      this.$http.get(`/oss/file/list/${refId}?typeId=${this.uploadTypeIds.PAPER_PDF}`)
        .then(({data: res}) => {
          if (res.data && res.data.length > 0) {
            let oss = res.data[0] // 理论上一个paper只能对应一个图纸文件
            window.open(oss.url)
          } else {
            this.$message({
              message: '对不起，未查询到相关文件！',
              type: 'error',
              duration: 5000
            })
          }
        })
    },

    getWbsTreeData () {
      this.wbsTreeLoading = true
      // 清空wbs数据
      this.wbsTreeData = []
      // 由于MPS计量支付平台的wbs是放在标段或是标段下的工区上创建的，图纸列表上只是选择了项目ID，
      // 所以这里要根据项目ID查询出该用户授权的标段集合,然后再查询出标段上的wbs。
      this.$http.get(
        '/mps/contract/openapi/briefs',
        {
          params: {
            prjId: this.dataForm.prjId,
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }

        let contractList = res.data || []
        // 循环标段，查询标段下的wbs
        for (let i = 0; i < contractList.length; i++) {
          let contractObj = contractList[i]
          this.$http.get('/mps/paper/findWbsChildren',
            {
              params: {
                'pid': 0,
                'contractId': contractObj.id
              }
            }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            res.data = res.data || []
            if (res.data.length > 0) {
              this.wbsTreeData.push.apply(this.wbsTreeData,res.data)
            }
            this.wbsTreeNodeIsLeaf = false
          }).catch(() => {
            return this.$message.error('出错了')
          }).finally(() => {
            if (i == contractList.length - 1) {
              this.wbsTreeData.push({
                id: '-1',
                name: '其他未分解图纸',
                isLeaf: false,
                children: []
              })
            }
          })
        }
      }).catch(() => {
        // do nothing
      }).finally(() => {
        this.wbsTreeLoading = false
      })
    },

    // 工程分解树节点点击事件
    wbsTreeNodeClick (data,node,ele) {
      // 清除 图纸目录的选择
      this.treeNodeIsLeaf = false
      this.dataForm.catalogId = ''
      this.$refs.tree.setCurrentKey(null)

      // 根据点击的节点是否为叶节点，修改本地变量，用于判断是否能新增
      // 点击高亮节点则取消高亮，并查全部清单的数据
      if (this.dataForm.wbsId == data.id) {
        this.wbsTreeNodeIsLeaf = false
        this.dataForm.wbsId = ''
        this.$refs.wbsTree.setCurrentKey(null)
      } else {
        this.wbsTreeNodeIsLeaf = node.isLeaf
        this.dataForm.wbsId = data.id
      }
      this.getDataList()
    },

    // 左侧tab页切换事件
    tabHandleClick (tab) {
      if (tab) {
        if (tab['name'] == 'catalog') { // 点击图纸目录
          // 清除 工程分解的选择
          this.wbsTreeNodeIsLeaf = false
          this.dataForm.wbsId = ''
          this.$refs.wbsTree.setCurrentKey(null)
        } else if (tab['name'] == 'wbs') { // 点击工程分解
          // 清除 图纸目录的选择
          this.treeNodeIsLeaf = false
          this.dataForm.catalogId = ''
          this.$refs.tree.setCurrentKey(null)
        }
        this.getDataList()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 200px);
  }
</style>
<template>
  <el-dialog :visible.sync="visible" title="图纸上传" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners" width="90%">
    <el-card>
      <el-upload ref="upload" multiple accept=".pdf,.dwg" action="" :file-list="fileList" :show-file-list="false"
          name="files" :auto-upload="false" :on-remove="handleRemove" :on-change="handleChange">
        <el-button slot="trigger" size="small" type="primary" @click="resetFileCount">选取文件</el-button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <el-button size="small" type="success" @click="beforeUpload">开始上传</el-button>
        <br><br>
        <span>{{catalogName}}，您已选择 <i style="color: red; font-weight: bold">{{fileList.length}}</i> 个文件</span>
      </el-upload>
      <h2>
        一次上传太多图纸需要很长时间，推荐您分批上传。
      </h2>
      <el-steps direction="vertical" :active="0">
        <el-step status="process" title="支持 pdf 和 dwg 格式的图纸文件。"></el-step>
        <!-- <el-step status="process" title="如果您未选择图纸目录，系统将尝试智能匹配对应的目录。"></el-step> -->
        <el-step status="process" title="建议您首先选择图纸目录后再上传。"></el-step>
      </el-steps>
    </el-card>
    <paper-upload-form v-if="paperUploadFormVisible" ref="paperUploadForm" @uploadAndSubmitForm="uploadAndSubmitFormHandle"></paper-upload-form>
  </el-dialog>
</template>

<script>
import Cookies from 'js-cookie'
import PaperUploadForm from './paper-upload-form'
export default {
  data () {
    return {
      visible: false,
      paperUploadFormVisible: false,
      prjId: '',
      catalogId: '',
      catalogName: '',
      statusId: '1', // 附件的状态（0：临时附件，1：永久附件）
      fileList: [],
      oldFileList: [],
      fileCount: 0,
      paperIds: [] // 图纸ID
    }
  },
  components: {
    PaperUploadForm
  },
  created: function () {
    //this.init()
  },
  methods: {
    init () {
      this.visible = true
      if (this.catalogId && this.catalogName) {
        this.catalogName = '选择的图纸目录：' + this.catalogName
      } else {
        // this.catalogName = '未选择图纸目录，上传后系统将尝试自动匹配'
        this.catalogName = '您未选择图纸目录'
      }
    },
    // 选择文件
    resetFileCount () {
      if (this.prjId) {
        this.fileCount = 0
        this.paperIds = []
      } else {
        this.$message({
          message: '请先选择项目！',
          type: 'error',
          duration: 4000
        })
        this.$refs.upload.abort()
        this.visible = false
      }
    },
    handleChange (file, fileList) { // 文件状态改变钩子
      // 第一次on-change获取fileList
      if (this.fileCount === 0) {
        // 多个文件fileList会变化
        this.oldFileList = Object.assign(this.fileList)
      }
      // 当前选择的文件是否已经存在，避免重复选择
      let isTrue = this.oldFileList.some((f) => {
        return f.name === file.name
      })

      if (isTrue) {
        this.$message.warning('请勿重复上传！')
        fileList.pop()
        return
      }
      this.fileList = fileList
      this.fileCount++
    },
    // 删除上传文件
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    beforeUpload () {
      if (this.fileList.length === 0) {
        this.$message.warning('请先选择要上传的文件！')
        return
      }
      // 打开新的窗口，填写设计信息和版本号等
      this.paperUploadFormVisible = true
      this.$nextTick(() => {
        this.$refs.paperUploadForm.init(this.paperIds)
      })
    },
    
    // 上传时填写设计信息、版本号后，用户点击了确认上传按钮
    uploadAndSubmitFormHandle(data) {
      const loading = this.$loading({
        lock: true,
        text: '正在上传并进行文件转换，请稍等...',
        customClass: 'create-isLoading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      let formData = new FormData()
      this.fileList.forEach(file => {
        formData.append('files', file.raw)
      })

      let dto = {
        prjId: this.prjId,
        catalogId: this.catalogId,
        version: data.version,
        majorId: data.majorId,
        stageId: data.stageId,
        designer: data.designer,
        reviewer: data.reviewer,
        verifier: data.verifier,
        designInCharge: data.designInCharge,
        majorInCharge: data.majorInCharge
      }
      formData.append('dto', JSON.stringify(dto))
      
      this.$http.post(`/mps/paper/multiUpload?_t=${new Date()}&statusId=${this.statusId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error({
              message: res.msg,
              duration: 5000
            })
          } else {
            this.paperIds = res.data
            
            this.$confirm('上传成功，是否发布图纸？', '确认', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              closeOnClickModal: false,
              type: 'warning'
            }).then(() => {
              // 发布图纸
              this.release()
            }).catch(() => {
              // do nothing
            })
            
          }
        }).catch(() => {
          this.$message({
            message: '图纸上传过程中出现错误，请稍后重试！',
            type: 'error',
            duration: 5000
          })
        }).finally(() => {
          loading.close()
        })
    },

    // 确认发布
    release () {
      const loading = this.$loading({
        lock: true,
        text: '正在发布图纸，请稍等...',
        customClass: 'create-isLoading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 补充：专业、设计阶段、设计人、复核人、审核人、设计负责人、专业负责人
      const data = this.paperIds.map(id => {
        return { id: id, prjId: this.prjId }
      })
      this.$http.post(`/mps/paper/release?_t=${new Date()}`, data)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          } else {
            this.$message({
              message: '图纸发布成功。',
              type: 'success',
              duration: 2000,
              onClose: () => {
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          }
        }).catch(() => {
          this.$message({
            message: '发布图纸过程中出现错误，请稍后重试！',
            type: 'error',
            duration: 5000
          })
        }).finally(() => {
          loading.close()
        })
    },

    // 不发布，直接删除
    del () {
      this.$confirm('您确定要删除刚上传的图纸?', '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '正在删除，请稍等...',
          spinner: 'el-icon-loading',
          customClass: 'create-isLoading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        if (this.paperIds && this.paperIds.length > 0) {
          this.$http.delete('/mps/paper', { 'data': this.paperIds })
            .then(({ data: res }) => {
              if (res.code !== 0) {
                return this.$message.error(res.msg)
              } else {
                this.visible = false
                this.$emit('refreshDataList')
              }
            }).catch(() => {
              this.$message({
                message: '图纸删除过程中出现错误，请稍后重试！',
                type: 'error',
                duration: 5000
              })
            }).finally(() => {
              loading.close()
            })
        } else {
          this.visible = false
          this.$emit('refreshDataList')
        }
      }).catch(() => {
        // do nothing
      })
    },
  }
}
</script>
<style lang="scss">
  .create-isLoading {
    .el-loading-spinner {
      top: 50%;
      left: 50%;
      margin-left: -55px;
      background: rgba(0, 0, 0, 0.7);
      padding: 20px;
      border-radius: 4px;
      width: auto;
      text-align: center;
      position: absolute;

      i {
        color: #eee;
      }

      .el-loading-text {
        color: #eee;
      }
    }
  }
</style>
